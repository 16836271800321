import React from 'react';

function Projects () {

  return (
    <div>
      <h3>This is the Projects Page!</h3>
    </div>
  )
}

export default Projects;