import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {

  return (
    <footer className="App-footer">
      <div className="container">
        <div className="row">
          <div className="col-4 col-sm-2 offset-1">
            {/* <h3>Links</h3> */}
            <ul className="list-unstyled">
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/about">About</Link></li>
              {/* <li><Link to="/projects">Projects</Link></li>
              <li><Link to="/blog">Articles</Link></li> */}
            </ul>
          </div>
          <div className="col-6 col-sm-3 text-center">
            {/* <h3>Contact</h3> */}
            <Link className="btn btn-social-icon btn-linkedin" target="_blank" to={{ pathname:"https://linkedin.com/in/rexosariemen" }}><i className="fa fa-linkedin" /></Link>{' '}
            <Link className="btn btn-social-icon btn-github" to={{ pathname: "https://github.com/rexosariemen" }} target="_blank"><i className="fa fa-github" /></Link>{' '}
            <Link className="btn btn-social-icon btn-twitter" to={{ pathname: "https://twitter.com/rexosariemen" }} target="_blank"><i className="fa fa-twitter" /></Link>{' '}
            {/* <a role="button" className="btn btn-social-icon btn-link" href="mailto:rexosariemen@gmail.com" target="_blank"><i className="fa fa-envelope-o" /></a> */}
          </div>
        </div>
      </div>
      <p className="copyright">&copy; 2020 rexosariemen &nbsp;</p>
    </footer>
  )
}

export default Footer;