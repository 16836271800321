import React, { Component } from 'react';

class Blog extends Component {

  render() {
    return (
      <div>
        <h3>
          This will be list of articles written and published on Medium or other
        </h3>
      </div>
    )
  }
}

export default Blog;