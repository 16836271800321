import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Main from './components/Main';

function App() {
  return (
    <div>
    <BrowserRouter>
      <div className="App">
        <Main />
      </div>
    </BrowserRouter>
    </div>
  );
}

export default App;
