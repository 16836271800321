import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import 'bootstrap-social/bootstrap-social.css';
import { Switch, Route, Redirect } from 'react-router-dom';

// Components
import Home from './Home';
import About from './About';
import Projects from './Projects';
import Blog from './Blog';
import Header from './Header';
import Footer from './Footer';


class Main extends React.Component {

  render() {
    return (
      <div >
        <Header />
        <div className="main-body">
          <Switch>
            <Route path='/home' component={Home} />
            <Route path='/about' component={About} />
            <Route path='/projects' component={Projects} />
            <Route path='/blog' component={Blog} />
            <Route path='/' component={Home} />
            <Redirect to='/home' />
          </Switch>
        </div>
        <Footer className='footer'/>
      </div>
    )
  }
}

export default Main;