import React from 'react';
import onTennisCourt from '../images/tenniscourt.jpeg';

function About () {

  return (
    <div className="about">
      <img src={onTennisCourt} alt="On Tennis Court"
        style={{ width:"300px", borderRadius:'5%', 
        border:'0.0px solid rgb(36 39 46)', marginBottom:'20px'
        }}
      />
      <p>A native of <a 
      href="https://en.wikipedia.org/wiki/Benin_City" 
      target="_blank" rel="noreferrer">Benin City, </a>
      or as we love to call her: 
       the ancient city of Benin 
      
     - <i><a 
        href="https://www.theguardian.com/cities/2016/mar/18/story-of-cities-5-benin-city-edo-nigeria-mighty-medieval-capital-lost-without-trace" 
        target="_blank" 
        rel="noreferrer"
      >in reference<br/> to her old glory.</a></i> Currently lives in Brooklyn, New York.</p>
      <p>I am a strong believer in Coding Bootcamps and the open source community for
        <br />the amazing contributions we make to the developers' community
      </p>
      <p>A co-creator of <a href="https://github.com/oslabs-beta/Allok8" rel="noreferrer" target="_blank"> allok8</a> - <i>a web-based K8s visualizer</i></p>
      {/* <h5>Content</h5> */}
    </div>
  )
}
/**
 * Born and grew up in Benin City; or the ancient city of Benin as we (indigenes) 
 * like to call it, in reference to her old glory. Currently lives in Brooklyn, New York.
 */

export default About;