import React from 'react';
import { Nav, Navbar, NavbarBrand, Collapse, NavItem, NavbarToggler } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import resume from '../images/rex_osariemen_resume.pdf';
import profile from '../images/navimage.png';

class Header extends React.Component {
  state = {
    isNavOpen: false,
  }

  toggleNav = () => {
    this.setState({
      isNavOpen: !this.state.isNavOpen,
    });
  }

  render() {
    return (
      <div className="App-header">
        <Navbar dark sticky="top" expand="md">
          <div className="container">
            <NavbarBrand className="mr-auto" href="/"><img id="nav-image" src={profile} height="30" width="30" alt="Rex Osariemen" /></NavbarBrand>
            <NavbarToggler onClick={this.toggleNav} className="mr-2"/>{' '}
            <Collapse className="" isOpen={this.state.isNavOpen} navbar>
              <Nav className="mr-auto nav-items" navbar style={{backgroundColor: ''}}>
                <NavItem>
                  <NavLink className="nav-link" to="/home">
                    <i className="fa fa-home fa-lg nav-item" /> Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link" to="/about">
                    <i className="fa fa-info fa-lg nav-item" /> About
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink className="nav-link" to="/projects">
                    <i className="fa fa-list fa-lg nav-item" /> Projects
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link last-item" to="/blog">
                    <i className="fa fa-book fa-lg nav-item" /> Blog
                  </NavLink>
                </NavItem> */}
              </Nav>
            </Collapse>
          </div>
        <a className="download-button" href={resume} target="_blank" rel="noreferrer">Resum&egrave;</a>
        </Navbar>
        <div>
        </div>
      </div>
    )
  }
}

export default Header;